import { AccBridgeIconWithTooltip } from '@mid-react-common/common';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowRight from '@mui/icons-material/ArrowRight';
import TreeItem from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import Typography from '@mui/material/Typography';
import { AccBridgeDataForIconsWithTooltip, AccBridgeProject, MIDAccBridgeFolder } from 'mid-types';
import React, { useCallback } from 'react';
import { Tree, TreeNode } from '../../../types/common';
import CreateNewFolder from './CreateNewFolder';
import FolderIcon from './FolderIcon';
import { FolderNode, FolderNodeContainer } from './FoldersTree.styles';
import { getACCBridgeProjectsData } from 'mid-utils';

interface FoldersTreeProps {
  folderUrn?: string;
  foldersTree: Tree | undefined;
  expandedTreeNodeIds: string[];
  handleFolderToggle: (_event: React.SyntheticEvent, nodeIds: string[]) => Promise<void>;
  handleFolderClick: (folderUrn: string) => void;
  showNewFolderCreation?: boolean;
  folderCreationError?: string;
  folderCreationRequestLoading?: boolean;
  handleNewFolderNameConfirmation?: (folderName: string) => void;
  handleNewFolderNameCancellation?: () => void;
  resetSubFolderCreationError?: () => void;
  editPermissionLookup?: Record<string, boolean>;
  bridgeIncomingProjectFoldersMap?: Map<string, MIDAccBridgeFolder>;
  bridgeOutgoingProjectFoldersMap?: Map<string, MIDAccBridgeFolder[]>;
  bridgeProjectsList?: AccBridgeProject[];
  selectedProjectId?: string | null;
}

export const FoldersTree: React.FC<FoldersTreeProps> = ({
  folderUrn,
  expandedTreeNodeIds,
  foldersTree,
  handleFolderClick,
  handleFolderToggle,
  showNewFolderCreation = false,
  folderCreationError,
  folderCreationRequestLoading,
  handleNewFolderNameConfirmation,
  handleNewFolderNameCancellation,
  resetSubFolderCreationError,
  editPermissionLookup,
  bridgeIncomingProjectFoldersMap,
  bridgeOutgoingProjectFoldersMap,
  bridgeProjectsList,
  selectedProjectId = null,
}) => {
  const renderTree = useCallback(
    (children: TreeNode[]) =>
      children.map((child) => {
        const { id, label } = child;
        const childrenNodes =
          foldersTree?.[id] && foldersTree[id].length > 0 ? renderTree(foldersTree[id]) : [<div key={id} />];

        const isCreatingASubFolder = showNewFolderCreation && id === folderUrn;
        const isFolderExpanded = expandedTreeNodeIds.some((nodeId: string) => nodeId === id) || isCreatingASubFolder;

        const accBridgeDataForTreeItem: AccBridgeDataForIconsWithTooltip = getACCBridgeProjectsData({
          folderUrn: id,
          incomingBridgeFoldersMap: bridgeIncomingProjectFoldersMap,
          outgoingBridgeFoldersMap: bridgeOutgoingProjectFoldersMap,
          bridgeProjectsList,
        });

        return (
          <TreeItem
            key={id}
            nodeId={id}
            label={
              <FolderNodeContainer data-id={id}>
                <FolderNode>
                  <FolderIcon isExpanded={isFolderExpanded} folderUrn={id} editPermissionLookup={editPermissionLookup} />
                </FolderNode>
                <Typography variant="body2" noWrap alignItems="center" width="100%">
                  {label}
                </Typography>
                <AccBridgeIconWithTooltip accBridgeData={accBridgeDataForTreeItem} selectedProjectId={selectedProjectId} />
              </FolderNodeContainer>
            }
            onClick={() => handleFolderClick(id)}
          >
            {isCreatingASubFolder && handleNewFolderNameConfirmation && handleNewFolderNameCancellation && (
              <CreateNewFolder
                onNewFolderNameConfirmation={handleNewFolderNameConfirmation}
                onNewFolderNameCancellation={handleNewFolderNameCancellation}
                folderCreationError={folderCreationError}
                folderCreationRequestLoading={folderCreationRequestLoading}
                resetSubFolderCreationError={resetSubFolderCreationError}
              />
            )}
            {childrenNodes}
          </TreeItem>
        );
      }),
    [
      bridgeIncomingProjectFoldersMap,
      bridgeOutgoingProjectFoldersMap,
      bridgeProjectsList,
      selectedProjectId,
      editPermissionLookup,
      expandedTreeNodeIds,
      folderCreationError,
      folderCreationRequestLoading,
      folderUrn,
      foldersTree,
      handleFolderClick,
      handleNewFolderNameCancellation,
      handleNewFolderNameConfirmation,
      resetSubFolderCreationError,
      showNewFolderCreation,
    ],
  );

  /**
   * Needs to pass empty string as selected urn to prevent MUI warning about
   * changing the uncontrolled selected state of TreeView to be controlled.
   */
  return (
    <TreeView
      selected={folderUrn || ''}
      defaultCollapseIcon={<ArrowDropDown />}
      defaultExpandIcon={<ArrowRight />}
      onNodeToggle={handleFolderToggle}
      expanded={expandedTreeNodeIds}
    >
      {renderTree(foldersTree?.root || [])}
    </TreeView>
  );
};

export default FoldersTree;
