import { Vendors } from '@adsk/offsite-dc-sdk';
import { NotificationContext, useCancellablePromise } from '@mid-react-common/common';
import { getForgeApiServiceInstance } from 'mid-api-services';
import { HubProjectFolderResponse, contentTypes, folderPermissionActions } from 'mid-types';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Tree, TreeNode } from 'types/common';
import text from 'global/text.json';
import { flattenFolderTree } from './FoldersTree.utils';
import { improveErrorDetailFromSubFolderCreationRequest } from './useFoldersTree.utils';
import { useQuery } from '@tanstack/react-query';
import { FOLDER_TREE_NODES, ROOT_FOLERS } from 'global/constants/reactQueryKeys';

export interface UseFoldersTreeProps {
  accountId: string | null;
  projectId: string | null;
  foldersTree: Tree | undefined;
  isSubfolderCreationInputVisible?: boolean;
  setFoldersTree: (folderTree: Tree | undefined) => void;
  setExpandedTreeNodeIds: (expandedNodeIds: string[]) => void;
  folderUrn: string | undefined;
  selectFolder: (folderUrn: string) => void;
  vendor?: Vendors;
  handleEditPermissions?: (additionalPermissions: Record<string, boolean>) => void;
}

export const rootNodeErrorReasons = {
  ROOT: 'ROOT',
  NOT_ROOT: 'NOT_ROOT',
} as const;

export type RootNodeErrorReason = (typeof rootNodeErrorReasons)[keyof typeof rootNodeErrorReasons];

export interface UseFoldersTreeState {
  errorReason: RootNodeErrorReason | null;
  rootNodes: TreeNode[] | undefined;
  hasEmptyRootNodes: boolean;
  handleFolderToggle: (_event: React.SyntheticEvent, nodeIds: string[]) => Promise<void>;
  handleSubFolderCreation: (folderName: string) => Promise<boolean>;
  subFolderCreationError?: string;
  subFolderCreationRequestLoading?: boolean;
  resetSubFolderCreationError?: () => void;
}

const useFoldersTree = ({
  accountId,
  projectId,
  foldersTree,
  folderUrn = '',
  vendor,
  isSubfolderCreationInputVisible,
  setFoldersTree,
  setExpandedTreeNodeIds,
  selectFolder,
  handleEditPermissions,
}: UseFoldersTreeProps): UseFoldersTreeState => {
  const { logAndShowNotification } = useContext(NotificationContext);

  const [subFolderCreationError, setSubFolderCreationError] = useState<string | undefined>(undefined);
  const [subFolderCreationRequestLoading, setSubFolderCreationRequestLoading] = useState<boolean | undefined>(false);

  const forgeApiService = getForgeApiServiceInstance();
  const cancellablePromise = useCancellablePromise();

  useEffect(() => {
    // The subFolderCreationError needs to be reset when the user
    // changes the folder selection context (determined by props in dependency array)
    resetSubFolderCreationState();
  }, [accountId, projectId, foldersTree, folderUrn, isSubfolderCreationInputVisible]);

  const getPermissionsForFolders = useCallback(
    async (projectId: string, folderNodes: TreeNode[], vendor?: Vendors): Promise<Record<string, boolean>> => {
      if (folderNodes.length === 0) {
        return {};
      }

      const permissionDetails = await cancellablePromise(
        forgeApiService.checkHubProjectFolderPermissions({
          projectId,
          vendor,
          folderUrns: folderNodes.map(({ id }) => id),
          permissionActions: [folderPermissionActions.CREATE, folderPermissionActions.UPLOAD],
        }),
      );

      if (permissionDetails.errors.length) {
        logAndShowNotification({
          message: text.folderTree.checkPermissionError,
          logExtraData: permissionDetails.errors,
        });
      }

      return permissionDetails.results.reduce<Record<string, boolean>>(
        (permissions, currentPermission) => ({ ...permissions, [currentPermission.id]: currentPermission.permission }),
        {},
      );
    },
    [forgeApiService, logAndShowNotification, cancellablePromise],
  );

  const getFolderTreeNodes = useCallback(
    async (
      projectId: string,
      folderUrn: string,
      signal: AbortSignal,
    ): Promise<{ result: Record<string, TreeNode[]>; expandedPath: string }> => {
      const folderTree = await forgeApiService.getFolderTree(projectId, folderUrn, signal);

      return flattenFolderTree(folderTree, folderUrn);
    },
    [forgeApiService],
  );

  const getRootFolders = useCallback(
    async ({
      accountId,
      projectId,
      vendor,
      signal,
    }: {
      accountId: string;
      projectId: string;
      vendor?: Vendors;
      signal: AbortSignal;
    }): Promise<TreeNode[]> =>
      (await forgeApiService.getHubProjectTopFolders({ hubId: accountId, projectId, vendor, signal }))
        .map((folder) => ({
          id: folder.id,
          label: folder.attributes.displayName,
        }))
        .sort((a, b) => (a.label > b.label ? 1 : -1)),
    [forgeApiService],
  );

  const getSubfolders = useCallback(
    async (projectId: string, folderId: string): Promise<TreeNode[]> => {
      const folderContents = await cancellablePromise(
        forgeApiService.getHubProjectFolderContents({ projectId, folderId, vendor }),
      );

      return folderContents.reduce<TreeNode[]>(
        (acc, currentContent) =>
          currentContent.type === contentTypes.FOLDERS
            ? [...acc, { id: currentContent.id, label: currentContent.attributes.displayName }]
            : acc,
        [],
      );
    },
    [vendor, forgeApiService, cancellablePromise],
  );

  // if the folderUrn is provided, it means that the whole tree related to this folder needs to be loaded
  const { data: folderTreeNodes, isError: hasErrorFetchingFolderTreeNodes } = useQuery({
    queryKey: [FOLDER_TREE_NODES, projectId, folderUrn],
    queryFn:
      projectId && folderUrn && !foldersTree
        ? async ({ signal }) => {
            const folderTree = await getFolderTreeNodes(projectId, folderUrn, signal);
            setFoldersTree(folderTree.result);

            // extract the env-dependent prefix of the folderUrn (URN)
            const folderUrnPrefix = folderUrn.match(/.*\./)![0];
            setExpandedTreeNodeIds(folderTree.expandedPath.split('/').map((item) => folderUrnPrefix + item));

            selectFolder(folderUrn);
            return folderTree;
          }
        : undefined,
    enabled: !!accountId && !!projectId && !foldersTree && !!folderUrn,
  });

  // if folderUrn is not provided, then we load the root folders first
  const { data: rootFolders, isError: hasErrorFetchingRootFolders } = useQuery({
    queryKey: [ROOT_FOLERS, accountId, projectId, vendor],
    queryFn:
      accountId && projectId
        ? async ({ signal }) => {
            const rootFolders = await getRootFolders({ accountId, projectId, vendor, signal });

            if (handleEditPermissions) {
              const editPermissions = await getPermissionsForFolders(projectId, rootFolders, vendor);
              handleEditPermissions(editPermissions);
            }

            setFoldersTree({ root: rootFolders });
            return rootFolders;
          }
        : undefined,
    enabled: !!accountId && !!projectId && !foldersTree && !folderUrn,
  });

  const handleFolderToggle = useCallback(
    async (_event: React.SyntheticEvent, nodeIds: string[]) => {
      setExpandedTreeNodeIds(nodeIds);
      if (!projectId || !nodeIds.length) {
        return;
      }

      const folderId = nodeIds[0];
      try {
        const subfolders = await getSubfolders(projectId, folderId);
        const editPermissions = await getPermissionsForFolders(projectId, subfolders, vendor);
        if (handleEditPermissions) {
          handleEditPermissions(editPermissions);
        }

        const newTree: Tree = {
          ...foldersTree,
          [folderId]: subfolders,
        };

        setFoldersTree(newTree);
      } catch (error) {
        logAndShowNotification({ error });
      }
    },
    [
      projectId,
      foldersTree,
      vendor,
      getSubfolders,
      logAndShowNotification,
      setFoldersTree,
      setExpandedTreeNodeIds,
      getPermissionsForFolders,
      handleEditPermissions,
    ],
  );

  const handleSubFolderCreation = useCallback(
    async (folderName: string): Promise<boolean> => {
      if (!projectId || !folderUrn || !foldersTree || !vendor) {
        return false;
      }
      setSubFolderCreationError(undefined);
      setSubFolderCreationRequestLoading(true);
      const { data, errors }: HubProjectFolderResponse = await forgeApiService.createHubProjectSubfolder(
        projectId,
        folderUrn,
        folderName,
        vendor,
      );

      if (data) {
        const newSubFolder = data;
        const newTree: Tree = {
          ...foldersTree,
          [folderUrn]: [{ id: newSubFolder.id, label: newSubFolder.attributes.displayName }, ...foldersTree[folderUrn]],
          [newSubFolder.id]: [],
        };
        if (handleEditPermissions) {
          handleEditPermissions({ [newSubFolder.id]: true });
        }
        setFoldersTree(newTree);
        selectFolder(newSubFolder.id);

        setSubFolderCreationError(undefined);
        setSubFolderCreationRequestLoading(false);
        return true;
      }

      if (errors && errors.length) {
        // we always show the first error message
        // as the api returns one error at a time even
        // if there are multiple errors.
        // Tested with duplicate name & restricted characters in name together
        const improvedErrorDetail = improveErrorDetailFromSubFolderCreationRequest(errors[0].status, errors[0].detail);
        setSubFolderCreationError(improvedErrorDetail);
        setSubFolderCreationRequestLoading(false);
      }

      return false;
    },
    [projectId, folderUrn, vendor, forgeApiService, foldersTree, setFoldersTree, selectFolder, handleEditPermissions],
  );

  const resetSubFolderCreationState = () => {
    setSubFolderCreationError(undefined);
    setSubFolderCreationRequestLoading(undefined);
  };

  const resetSubFolderCreationError = () => {
    setSubFolderCreationError(undefined);
  };

  const rootNodes = rootFolders || folderTreeNodes?.result.root;

  return {
    errorReason: hasErrorFetchingRootFolders
      ? rootNodeErrorReasons.ROOT
      : hasErrorFetchingFolderTreeNodes
        ? rootNodeErrorReasons.NOT_ROOT
        : null,
    rootNodes,
    hasEmptyRootNodes: Array.isArray(rootNodes) && rootNodes.length === 0,
    handleFolderToggle,
    handleSubFolderCreation,
    subFolderCreationError,
    subFolderCreationRequestLoading,
    resetSubFolderCreationError,
  };
};

export default useFoldersTree;
