import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export const TooltipBodyTypography = styled(Typography)`
  font-weight: ${({ theme }) => theme.var.tooltipBodyFontWeight};
  font-size: ${({ theme }) => theme.var.tooltipBodyFontSize}px;
`;

export const TooltipFooterTypography = styled(Typography)`
  font-weight: ${({ theme }) => theme.var.tooltipFooterFontWeight};
`;

export const TooltipTypography = styled(Typography)`
  margin-bottom: ${({ theme }) => `${theme.var.marginBase}px`};
`;
