import { FilledFolder, Spacing, LockedFolder } from '@mid-react-common/common';
import { Section } from '../RFOModal.styles';
import Typography from '@mui/material/Typography';
import text from 'global/text.json';
import Button from '@mui/material/Button';
import useRFOModalStore from 'context/RFOModalStore/useRFOModalStore';
import HubIcon from 'images/projectIcons/hub.svg?react';
import { useShallow } from 'zustand/react/shallow';
import Box from '@mui/material/Box';
import { ProjectIcon } from 'components/Commons/ProjectIcon/ProjectIcon';
import { useTheme } from '@mui/material/styles';
import { BreadcrumbsWrapper, LinkWithPointerCursor } from './CheckoutStep.styles';
import { useMemo, useState } from 'react';
import { rfoModalTestIds } from 'tests/helpers/dataTestIds';
import { getFolderNameAndFullPath } from './utils';

const checkoutStepText = text.rfoModal.checkoutStep;
const checkoutStepTestIds = rfoModalTestIds.checkoutStep;

const LocationInfo: React.FC = () => {
  const theme = useTheme();
  const {
    uploadLocationProject,
    uploadFolderUrn,
    uploadLocationTree,
    editPermissions,
    isGeneratingOutputs,
    setUploadLocationStepActive,
  } = useRFOModalStore(
    useShallow((state) => ({
      uploadLocationProject: state.uploadLocationProject,
      uploadLocationTree: state.uploadLocationTree,
      uploadFolderUrn: state.uploadFolderUrn,
      editPermissions: state.editPermissions,
      isGeneratingOutputs: state.isGeneratingOutputs,
      setUploadLocationStepActive: state.setUploadLocationStepActive,
    })),
  );

  const [showBreadcrumbs, setShowBreadcrumbs] = useState(false);

  const handleShowBreadcrumbs = () => {
    setShowBreadcrumbs((prev) => !prev);
  };

  const { folderName: uploadFolderName, fullPath: uploadFolderBreadcrumbs } = useMemo(
    () => getFolderNameAndFullPath(uploadFolderUrn, uploadLocationTree),
    [uploadFolderUrn, uploadLocationTree],
  );

  return (
    <Section>
      <Box display="flex" alignItems="baseline" justifyContent="space-between">
        <Typography fontWeight="bold" variant="h3">
          {checkoutStepText.uploadLocation}
        </Typography>
        <Button
          data-testid={checkoutStepTestIds.uploadLocationStepChangeButton}
          onClick={setUploadLocationStepActive}
          disabled={isGeneratingOutputs}
        >
          {checkoutStepText.change}
        </Button>
      </Box>
      <Spacing />
      <Box
        display="flex"
        data-testid={checkoutStepTestIds.uploadLocationAccountName}
        gap={`${theme.var.gapBase}px`}
        marginBottom={`${theme.var.marginBase}px`}
      >
        <Box>
          <HubIcon height={theme.var.projectIconSize} width={theme.var.projectIconSize} />
        </Box>
        <Typography>{uploadLocationProject?.accountName}</Typography>
      </Box>
      <Box
        data-testid={checkoutStepTestIds.uploadLocationProjectName}
        display="flex"
        gap={`${theme.var.gapBase}px`}
        marginBottom={`${theme.var.marginBase}px`}
      >
        {uploadLocationProject && (
          <Box>
            <ProjectIcon vendor={uploadLocationProject.vendor} />
          </Box>
        )}
        <Typography>{uploadLocationProject?.name}</Typography>
      </Box>
      {uploadFolderBreadcrumbs && (
        <Box marginBottom={`${theme.var.marginBase}px`}>
          <LinkWithPointerCursor
            data-testid={checkoutStepTestIds.showHideBreadcrumbs}
            underline="none"
            onClick={handleShowBreadcrumbs}
          >
            {showBreadcrumbs ? checkoutStepText.hidePathDetails : checkoutStepText.showPathDetails}
          </LinkWithPointerCursor>
        </Box>
      )}
      {showBreadcrumbs && (
        <BreadcrumbsWrapper data-testid={checkoutStepTestIds.folderPathBreadcrumbs} className="mid-bg-shadow" maxItems={20}>
          {uploadFolderBreadcrumbs &&
            uploadFolderBreadcrumbs.map((item) => (
              <Box key={item.label} display="flex" gap={`${theme.var.gapBase}px`}>
                {editPermissions[item.id] ? (
                  <Box>
                    <FilledFolder data-testid={checkoutStepTestIds.folderIcon} />
                  </Box>
                ) : (
                  <Box>
                    <LockedFolder data-testid={checkoutStepTestIds.lockedFolderIcon} />
                  </Box>
                )}
                <Typography color={theme.palette.text.primary}>{item.label}</Typography>
              </Box>
            ))}
        </BreadcrumbsWrapper>
      )}
      <Box data-testid={checkoutStepTestIds.uploadLocationFolderName} display="flex" gap={`${theme.var.gapBase}px`}>
        <Box>
          <FilledFolder />
        </Box>
        <Typography noWrap>{uploadFolderName}</Typography>
      </Box>
    </Section>
  );
};

export default LocationInfo;
