import { Spacing } from '@mid-react-common/common';
import { Section } from '../../RFOModal.styles';
import Typography from '@mui/material/Typography';
import text from 'global/text.json';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import { rfoModalTestIds } from 'tests/helpers/dataTestIds';
import OutputsTableHeader from './OutputsTableHeader';
import OutputsTableBody from './OutputsTableBody';
import OutputsTableFooter from './OutputsTableFooter';
import useOutputsTable from './useOutputsTable';
import useRFOModalStore from 'context/RFOModalStore/useRFOModalStore';
const checkoutStepText = text.rfoModal.checkoutStep;
const checkoutStepTestIds = rfoModalTestIds.checkoutStep;

const OutputsTable: React.FC = () => {
  const isGeneratingOutputs = useRFOModalStore((state) => state.isGeneratingOutputs);
  const { tableData, totalQuantity, totalCostInUnicodeValue, handleFileTypesChangeClick } = useOutputsTable();

  return (
    <Section>
      <Box display="flex" alignItems="baseline" justifyContent="space-between">
        <Typography fontWeight="bold" variant="h3" gutterBottom>
          {checkoutStepText.fileTypes}
        </Typography>
        <Button
          data-testid={checkoutStepTestIds.fileTypesStepChangeButton}
          onClick={handleFileTypesChangeClick}
          disabled={isGeneratingOutputs}
        >
          {checkoutStepText.change}
        </Button>
      </Box>
      <Spacing />
      <TableContainer>
        <Table>
          <OutputsTableHeader />
          <OutputsTableBody tableData={tableData} />
          <OutputsTableFooter totalQuantity={totalQuantity} totalCostInUnicodeValue={totalCostInUnicodeValue} />
        </Table>
      </TableContainer>
    </Section>
  );
};

export default OutputsTable;
