import Close from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import { DetailsSidebarDivider, DetailsSidebarWrapper } from 'components/Commons/DetailsSidebar/DetailsSidebar.style';
import DetailsSidebarContent from 'components/Commons/DetailsSidebar/DetailsSidebarContent';
import DetailsSidebarHeader from 'components/Commons/DetailsSidebar/DetailsSidebarHeader';
import KeyValueTable from 'components/Commons/KeyValueTable/KeyValueTable';
import { SidebarThumbnailContainer } from 'components/Commons/SidebarThumbnail/SidebarThumbnail.style';
import { useThumbnail } from 'global/hooks/hooks';
import React, { useEffect, useMemo, useState } from 'react';
import MissingThumbnail from '../../../../src/images/missingThumbnail.jpg';
import text from '../../../global/text.json';
import { KeyValueTableRow } from '../../../types/KeyValueTable';
import { Instance } from '../../../types/product';
import CustomAccordion from '../../Commons/CustomAccordion/CustomAccordion';
import CustomPagination from '../../Commons/CustomPagination/CustomPagination';
import SidebarThumbnail from '../../Commons/SidebarThumbnail/SidebarThumbnail';
import { ProductRelease, Variant } from '@adsk/offsite-dc-sdk';
import { getProductInfo, transformVariantInputsIntoTableRows } from './InstanceDetailsPanel.utils';
import { AccBridgeData, getBridgeDataFromInstance } from 'utils/bridge';
import { DCProductUIExtension } from 'mid-types';

const instanceDetailsText = text.instanceDetailsPanel;

interface InstanceDetailsPanelProps {
  visibleInstances: Instance[];
  selectedInstancesIds: string[];
  currentProductRelease: DCProductUIExtension<ProductRelease> | undefined;
  variants: Variant[];
  handleInstanceDetailsPanelVisibility: (showInstancesDetailsPanel: boolean) => void;
}

const InstanceDetailsPanel: React.FC<InstanceDetailsPanelProps> = ({
  visibleInstances,
  selectedInstancesIds,
  currentProductRelease,
  variants,
  handleInstanceDetailsPanelVisibility,
}): JSX.Element => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleCloseButtonClick = () => {
    handleInstanceDetailsPanelVisibility(false);
  };
  const handlePaginationChange = (index: number) => {
    setSelectedIndex(index);
  };

  const selectedVisibleInstances = useMemo(
    () => visibleInstances.filter((instance) => selectedInstancesIds.includes(instance.id)),
    [visibleInstances, selectedInstancesIds],
  );

  const currentInstance: Instance | null =
    selectedIndex !== null && selectedIndex < selectedVisibleInstances.length
      ? selectedVisibleInstances[selectedIndex]
      : null;

  const currentVariant = useMemo(
    () => variants.find((variant) => variant.variantId === currentInstance?.variantId),
    [variants, currentInstance],
  );

  const accBridgeData: AccBridgeData | null = useMemo(
    () => (currentInstance ? getBridgeDataFromInstance(currentInstance) : null),
    [currentInstance],
  );

  const { thumbnail, isThumbnailLoading } = useThumbnail({
    thumbnailId: currentVariant?.thumbnail,
    thumbnailProjectId: currentVariant?.tenancyId,
    incomingAccBridgeData: {
      targetProjectId: accBridgeData?.targetProjectId,
      sourceProjectId: accBridgeData?.sourceProjectId,
    },
  });

  //  Check if there are any instances to display, otherwise close the panel
  useEffect(() => {
    if (selectedVisibleInstances.length === 0) {
      handleInstanceDetailsPanelVisibility(false);
    } else {
      handleInstanceDetailsPanelVisibility(true);
    }
  }, [selectedVisibleInstances.length, handleInstanceDetailsPanelVisibility]);

  // Set Table Columns
  const productInfo: KeyValueTableRow[] = getProductInfo(currentProductRelease, currentInstance);

  const inputsInfo: KeyValueTableRow[] = transformVariantInputsIntoTableRows(
    currentVariant?.inputs || [],
    currentProductRelease?.rules?.formRules?.code,
  );
  //TODO: Add output info
  return (
    <DetailsSidebarWrapper>
      <DetailsSidebarHeader>
        <CustomPagination
          count={selectedVisibleInstances.length}
          currentIndex={selectedIndex}
          onChange={handlePaginationChange}
        />
        <IconButton onClick={handleCloseButtonClick} data-testid="close-model-details-button">
          <Close />
        </IconButton>
      </DetailsSidebarHeader>
      <DetailsSidebarDivider />
      <DetailsSidebarContent>
        <CustomAccordion title={instanceDetailsText.preview}>
          {isThumbnailLoading ? (
            <SidebarThumbnailContainer className="mid-border-shadow">
              <CircularProgress />
            </SidebarThumbnailContainer>
          ) : (
            <SidebarThumbnail src={thumbnail || MissingThumbnail} />
          )}
        </CustomAccordion>
        <CustomAccordion title={instanceDetailsText.productSummary}>
          <KeyValueTable data={productInfo} />
        </CustomAccordion>
        <CustomAccordion title={`${instanceDetailsText.inputs} (${inputsInfo.length})`}>
          <KeyValueTable data={inputsInfo} />
        </CustomAccordion>
      </DetailsSidebarContent>
    </DetailsSidebarWrapper>
  );
};

export default InstanceDetailsPanel;
