import { ProductRelease, Variant } from '@adsk/offsite-dc-sdk';
import { NotificationContext, StateSetter } from '@mid-react-common/common';
import { GridColDef } from '@weave-mui/data-grid';
import ModelSelectionContext from 'context/ModelSelectionStore/ModelSelection.context';
import ProductContext from 'context/ProductStore/Product.context';
import { instancesVisibilityFilter } from 'global/constants/common';
import { useNavigationRoutines } from 'global/hooks/hooks';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import viewerService from 'services/viewer/viewerService';
import { Instance } from 'types/product';
import text from '../../../global/text.json';
import { FolderContentRow } from '../ModelsFolderContent/ModelsFolderContent.types';
import useInstancePanelData from './hooks/useInstancePanelData';
import useInstancePanelUI from './hooks/useInstancePanelUI';
import useInstancesSelection from './hooks/useInstancesSelection';
import useMIDElementsFromViewer from './hooks/useMIDElementsFromViewer';
import { getAllOutputs } from './RFOModal/FileTypesStep/FileTypesStep.utils';

interface UseInstancesPanelState {
  instancesSidebarExpanded: boolean;
  selectedProductRelease: ProductRelease | undefined;
  outputsGenerationDisabledDueToInstances: boolean;
  instancesOutputCount: number;
  dataGridColumns: GridColDef[];
  productsLoading: boolean;
  hasDataGridInstancesBeenInitialized: boolean;
  showInstanceDetailsPanel: boolean;
  variantsList: Variant[];
  localSelectedInstances: Instance[] | undefined;
  selectedInstancesIds: string[] | undefined;
  selectionFilter: string;
  setSelectionFilter: StateSetter<string>;
  handleInstancesSelection: (selectedInstanceIds: string[]) => void;
  handleToggleExpanded: () => void;
  handleSelectedInstances: () => void;
  handleInstanceDetailsPanelVisibility: (showInstanceDetailsPanel: boolean) => void;
}

export const useInstancesPanel = (): UseInstancesPanelState => {
  const {
    variants: variantsList,
    productsLoading,
    selectedProductRelease,
    dataGridInstances,
    setSelectedInstances,
  } = useContext(ProductContext);
  const { currentlyOpenModel, selectedFolderUrn, setCurrentlyOpenModel, setSelectedFolderUrn, setSelectedModelId } =
    useContext(ModelSelectionContext);
  const { logAndShowNotification } = useContext(NotificationContext);
  const navigate = useNavigate();

  const [localSelectedInstances, setLocalSelectedInstances] = useState<Instance[] | undefined>(undefined);
  const [selectionFilter, setSelectionFilter] = useState(instancesVisibilityFilter.ALL);

  const [searchParams, setSearchParams] = useSearchParams();
  const { parseOpenModelURLParameter, serializeOpenModelDataAsURLParameter } = useNavigationRoutines();
  const openModelURLParameter = searchParams.get('openModel');

  // Data
  const { selectedInstancesIds, hasDataGridInstancesBeenInitialized } = useInstancePanelData({ localSelectedInstances });

  // UI
  const { expanded, handleToggleExpanded, showInstanceDetailsPanel, setShowInstanceDetailsPanel, dataGridColumns } =
    useInstancePanelUI();

  // Generate Outputs
  const instancesBelongToCurrentDatagrid = dataGridInstances.filter((dataGridInstance) =>
    localSelectedInstances?.map((instance) => instance.id).includes(dataGridInstance.id),
  );
  const outputsGenerationDisabledDueToInstances =
    !localSelectedInstances || !localSelectedInstances.length || !instancesBelongToCurrentDatagrid.length;

  const instancesOutputCount = getAllOutputs(selectedProductRelease, localSelectedInstances).length;

  const handleSelectedInstances = () => {
    setSelectedInstances(localSelectedInstances);
  };

  // MID elements instances from Viewer
  useMIDElementsFromViewer();

  const handleInstanceDetailsPanelVisibility = (showInstanceDetailsPanel: boolean) => {
    setShowInstanceDetailsPanel(showInstanceDetailsPanel);
  };

  // Instances Selection
  const { handleInstancesSelection } = useInstancesSelection({
    localSelectedInstances,
    setLocalSelectedInstances,
  });

  useEffect(() => {
    if (localSelectedInstances && localSelectedInstances?.length) {
      setShowInstanceDetailsPanel(true);
    }
  }, [localSelectedInstances, setShowInstanceDetailsPanel]);

  useEffect(() => {
    viewerService.resizeViewer();
  }, [showInstanceDetailsPanel]);

  useEffect(() => {
    const shouldPreventInstancesTabOpening = !currentlyOpenModel && !openModelURLParameter;
    const shouldRestoreOpenModelStateByURLParameter = !currentlyOpenModel && openModelURLParameter;
    const shouldStoreOpenModelStateInURLParameter = currentlyOpenModel && !openModelURLParameter;

    if (shouldPreventInstancesTabOpening) {
      navigate('..');
    }

    if (shouldRestoreOpenModelStateByURLParameter) {
      try {
        const parsedOpenModel = parseOpenModelURLParameter(openModelURLParameter);

        setCurrentlyOpenModel({
          lmvModelFileId: parsedOpenModel.lmvModelFileId,
          id: parsedOpenModel.itemUrn,
        } as FolderContentRow);

        setSelectedFolderUrn(parsedOpenModel.folderUrn);
        setSelectedModelId(parsedOpenModel.itemUrn);
      } catch (error) {
        logAndShowNotification({ error, message: text.common.malformedOpenModel });
        navigate('..');
      }
    }

    if (shouldStoreOpenModelStateInURLParameter) {
      searchParams.set('openModel', serializeOpenModelDataAsURLParameter(currentlyOpenModel, selectedFolderUrn));
      setSearchParams(searchParams);
    }
  }, [
    currentlyOpenModel,
    openModelURLParameter,
    selectedFolderUrn,
    navigate,
    setCurrentlyOpenModel,
    setSelectedFolderUrn,
    setSelectedModelId,
    searchParams,
    setSearchParams,
    logAndShowNotification,
    parseOpenModelURLParameter,
    serializeOpenModelDataAsURLParameter,
  ]);

  return {
    instancesSidebarExpanded: expanded,
    selectedProductRelease,
    outputsGenerationDisabledDueToInstances,
    instancesOutputCount,
    localSelectedInstances,
    dataGridColumns,
    productsLoading,
    hasDataGridInstancesBeenInitialized,
    showInstanceDetailsPanel,
    variantsList,
    selectedInstancesIds,
    selectionFilter,
    setSelectionFilter,
    handleInstancesSelection,
    handleToggleExpanded,
    handleInstanceDetailsPanelVisibility,
    handleSelectedInstances,
  };
};
