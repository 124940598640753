import { DownloadURLPayload, DownloadUrlResponse } from '@adsk/offsite-dc-sdk';
import { NotificationContext } from '@mid-react-common/common';
import { AccBridgeDownloadUrlQueryParams, getDcApiServiceInstance } from 'mid-api-services';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useMatches, useNavigate } from 'react-router-dom';
import { getBase64Image } from 'utils/thumbnail';
import { FolderContentRow } from '../../components/OutputsPage/ModelsFolderContent/ModelsFolderContent.types';
import text from '../../global/text.json';
import { OpenModelURLParameter } from '../../types/common';

export const usePrevious = (value: any): any => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value; //assign the value of ref to the argument
  }, [value]); //this code will run when the value of 'value' changes
  return ref.current; //in the end, return the current ref value.
};

export const useDefaultNavigation = (routeFromId: string, routeToId: string): void => {
  const location = useLocation();
  const navigate = useNavigate();
  const routes = useMatches();

  const foundRoute = routes.find((route) => route.id === routeFromId);

  useEffect(() => {
    // check if the current location exactly corresponds to the 'routeFromId'
    // if so, navigate to the provided 'routeToId' route
    if (foundRoute && foundRoute.pathname === location.pathname) {
      navigate(routeToId, {
        replace: true,
      });
    }
  }, [navigate, foundRoute, location.pathname, routeToId]);
};

export type UseNavigationRoutinesState = {
  parseOpenModelURLParameter: (openModelURLParameter: string) => OpenModelURLParameter;
  serializeOpenModelDataAsURLParameter: (currentlyOpenModel: FolderContentRow, selectedFolderUrn: string) => string;
};
export const useNavigationRoutines = (): UseNavigationRoutinesState => {
  const serializeOpenModelDataAsURLParameter = useCallback(
    (currentlyOpenModel: FolderContentRow, selectedFolderUrn: string) => {
      const openModelURLParameter: OpenModelURLParameter = {
        lmvModelFileId: currentlyOpenModel.lmvModelFileId,
        folderUrn: selectedFolderUrn,
        itemUrn: currentlyOpenModel.id,
      };

      return window.btoa(JSON.stringify(openModelURLParameter));
    },
    [],
  );

  // memoize the function since it's typically used in useEffect of the consumer components
  const parseOpenModelURLParameter = useCallback(
    (openModelURLParameter: string): OpenModelURLParameter => JSON.parse(window.atob(openModelURLParameter)),
    [],
  );

  return {
    parseOpenModelURLParameter,
    serializeOpenModelDataAsURLParameter,
  };
};

interface UseThumbnailState {
  thumbnail: string | null;
  isThumbnailLoading: boolean;
}
interface UseThumbnailProps {
  thumbnailId?: string;
  thumbnailProjectId?: string;
  incomingAccBridgeData?: AccBridgeDownloadUrlQueryParams;
}
export function useThumbnail({
  thumbnailId,
  thumbnailProjectId,
  incomingAccBridgeData,
}: UseThumbnailProps): UseThumbnailState {
  const { logAndShowNotification } = useContext(NotificationContext);
  const [isThumbnailLoading, setIsThumbnailLoading] = useState(true);
  const [thumbnail, setThumbnail] = useState<string | null>(null);
  // Cache locally the thumbnails
  const fetchedThumbnails = useMemo(() => new Map<string, string>(), []);

  const fetchThumbnail = useCallback(async () => {
    setIsThumbnailLoading(true);
    if (!thumbnailId || !thumbnailProjectId) {
      setIsThumbnailLoading(false);
      return;
    }
    // Check if the thumbnail is already downloaded
    if (fetchedThumbnails.has(thumbnailId)) {
      setThumbnail(fetchedThumbnails.get(thumbnailId) || null);
      setIsThumbnailLoading(false);
    } else {
      // Otherwise, download the thumbnail
      try {
        const downloadURLPayload: DownloadURLPayload = {
          objectKey: thumbnailId,
        };
        const downloadUrlResponse: DownloadUrlResponse = await getDcApiServiceInstance().downloadURL({
          projectId: thumbnailProjectId,
          downloadURLPayload,
          incomingAccBridgeData,
        });
        if (downloadUrlResponse.signedUrl) {
          const base64Image = await getBase64Image(downloadUrlResponse.signedUrl);
          fetchedThumbnails.set(thumbnailId, base64Image);
          setThumbnail(base64Image);
        }
      } catch (error) {
        logAndShowNotification({
          message: text.useThumbnail.thumbnailLoadError,
        });
      } finally {
        setIsThumbnailLoading(false);
      }
    }
  }, [thumbnailId, thumbnailProjectId, incomingAccBridgeData, fetchedThumbnails, logAndShowNotification]);

  useEffect(() => {
    if (thumbnailId) {
      fetchThumbnail();
    } else {
      setIsThumbnailLoading(false);
    }
  }, [thumbnailId, fetchThumbnail]);

  return { thumbnail, isThumbnailLoading };
}
