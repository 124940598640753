import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

export const IconWrapper = styled(Box)`
  margin-right: ${({ theme }) => theme.var.marginBase / 2}px;
`;

export const ReleaseNotesIconButton = styled(IconButton)`
  padding: 0 0 0 ${({ theme }) => theme.var.marginBase}px;
`;

export const ReleaseDetailsListItem = styled('li')`
  list-style-type: none;
`;
