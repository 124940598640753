import { LDProviderConfigWithAnonymousAnalytics } from '@mid-react-common/common';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { initializeAmplitude } from 'mid-utils';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { ampli } from './ampli';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Router } from './routes/Router';

(async () => {
  initializeAmplitude.bind(ampli)();
  const LDProvider = await asyncWithLDProvider(LDProviderConfigWithAnonymousAnalytics);
  const container = document.getElementById('root');
  const root = createRoot(container!);

  root.render(
    <React.StrictMode>
      <LDProvider>
        <Router />
      </LDProvider>
    </React.StrictMode>,
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
