import { DarkGreyTooltip } from '@mid-react-common/common';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import text from 'global/text.json';
import { accBridgeLink } from 'mid-utils';
import { TooltipFooterTypography } from 'styles/Common.styles';
import { rfoModalTestIds } from 'tests/helpers/dataTestIds';
import { FolderTooltipBodyTypography, FolderTooltipContainer } from './UploadLocationStep.styles';

const rfoModalText = text.rfoModal;

interface BridgeFolderTooltipProps {
  messages: string[];
}

export const BridgeFolderWarningIcon: React.FC<BridgeFolderTooltipProps> = ({ messages }) => (
  <DarkGreyTooltip
    title={
      <FolderTooltipContainer>
        {messages.map((text, index) => (
          <FolderTooltipBodyTypography key={index}>{text}</FolderTooltipBodyTypography>
        ))}
        <Link href={accBridgeLink} target="_blank" underline="none" rel="noopener noreferrer">
          <TooltipFooterTypography>{rfoModalText.informedDesignAndAccBridge}</TooltipFooterTypography>
        </Link>
      </FolderTooltipContainer>
    }
    arrow
    placement="top"
  >
    <IconButton size="small" data-testid={rfoModalTestIds.uploadLocationStep.bridgeFolderIconButton}>
      <InfoOutlinedIcon />
    </IconButton>
  </DarkGreyTooltip>
);
