import { useFlags } from 'launchdarkly-react-client-sdk';
import { useQuery, useQueries } from '@tanstack/react-query';
import { getAccBridgeApiServiceInstance, getForgeApiServiceInstance } from 'mid-api-services';
import { AccBridgeProject, MIDAccBridgeFolder } from 'mid-types';
import { useAccIncomingBridge } from './useAccIncomingBridge';
import { useAccOutgoingBridge } from './useAccOutgoingBridge';

interface UseAccBridgeProps {
  projectId: string | null;
  // Both Acc project ID or BIM360 project ID will work with ACC Bridge
  // If BIM360 project ID is passed to ACC Bridge Endpoint, it will just return an empty array
  isAccProject: boolean;
}

interface UseAccBridgeState {
  incomingBridgeFoldersMap: Map<string, MIDAccBridgeFolder> | undefined;
  outgoingBridgeFoldersMap: Map<string, MIDAccBridgeFolder[]> | undefined;
  bridgeProjectsList: AccBridgeProject[] | undefined;
  isLoadingBridgeFolders: boolean;
  hasErrors: boolean;
}

const BRIDGE_PROJECTS = 'bridgeProjects';
const ALLOWED_BRIDGE_PROJECTS = 'allowedBridgeProjects';

export const useAccBridge = ({ projectId, isAccProject }: UseAccBridgeProps): UseAccBridgeState => {
  const { enableAccBridge } = useFlags();

  const accBridgeService = getAccBridgeApiServiceInstance();
  const forgeApiService = getForgeApiServiceInstance();

  const { data: bridgeProjectsList } = useQuery({
    queryKey: [BRIDGE_PROJECTS, projectId],
    queryFn: ({ signal }) => (projectId ? accBridgeService.getBridgeProjects(projectId, signal) : undefined),
    enabled: !!projectId && isAccProject && !!enableAccBridge,
  });

  // Filter out the bridge projects that users are not allowed to access
  const { data: allowedBridgeProjectsList } = useQueries({
    queries:
      bridgeProjectsList?.map((bridgeProject) => ({
        queryKey: [ALLOWED_BRIDGE_PROJECTS, bridgeProject.projectId],
        queryFn: async () => await forgeApiService.getProjectNameById(bridgeProject.projectId, true),
        enabled: !!bridgeProjectsList && !!enableAccBridge,
      })) || [],
    combine: (results) => ({
      data: results
        .map((result) => {
          const allowedBridgeProject = result.data;
          if (allowedBridgeProject) {
            return bridgeProjectsList?.find((project) => project.projectId === allowedBridgeProject.id);
          }
        })
        .filter((project) => !!project) as AccBridgeProject[],
    }),
  });

  const {
    incomingBridgeFoldersMap,
    isLoadingBridgeFolders: isLoadingIncomingBridgeFolders,
    hasErrors: hasErrorInIncomingBridgeFolders,
  } = useAccIncomingBridge({ projectId, isAccProject });

  const {
    outgoingBridgeFoldersMap,
    isLoadingBridgeFolders: isLoadingOutgoingBridgeFolders,
    hasErrors: hasErrorInOutgoingBridgeFolders,
  } = useAccOutgoingBridge({ projectId, isAccProject });

  return {
    incomingBridgeFoldersMap,
    outgoingBridgeFoldersMap,
    bridgeProjectsList: allowedBridgeProjectsList,
    isLoadingBridgeFolders: isLoadingIncomingBridgeFolders || isLoadingOutgoingBridgeFolders,
    hasErrors: hasErrorInIncomingBridgeFolders || hasErrorInOutgoingBridgeFolders,
  };
};
