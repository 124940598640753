import { DarkGreyTooltip } from '@mid-react-common/common';
import { CanGenerateOutputsErrorContainer } from '../RFOModal.styles';
import Link from '@mui/material/Link';
import { TooltipFooterTypography, TooltipTypography } from '../../../../../styles/Common.styles';
import Box from '@mui/material/Box';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTheme } from '@mui/material';
import { rfoModalTestIds } from '../../../../../tests/helpers/dataTestIds';

interface CanGenerateOutputsErrorProps {
  canGenerateOutputsError: { title: string; message: string; footerText?: string; footerLink?: string };
}
const CanGenerateErrorTooltip: React.FC<CanGenerateOutputsErrorProps> = ({ canGenerateOutputsError }) => {
  const theme = useTheme();
  return (
    <DarkGreyTooltip
      title={
        <Box padding={`${theme.var.paddingBase}px`}>
          <TooltipTypography fontWeight="bolder">{canGenerateOutputsError.title}</TooltipTypography>
          <TooltipTypography>{canGenerateOutputsError.message}</TooltipTypography>
          <Link href={canGenerateOutputsError.footerLink} target="_blank" underline="none" rel="noopener noreferrer">
            <TooltipFooterTypography>{canGenerateOutputsError.footerText}</TooltipFooterTypography>
          </Link>
        </Box>
      }
      arrow
      placement="top"
      data-testid={rfoModalTestIds.checkoutStep.canGenerateOutputsErrorTooltip}
    >
      <CanGenerateOutputsErrorContainer>
        <Box>
          <WarningAmberIcon color="warning" />
        </Box>
        <Typography display="inline">{canGenerateOutputsError.title}</Typography>
      </CanGenerateOutputsErrorContainer>
    </DarkGreyTooltip>
  );
};

export default CanGenerateErrorTooltip;
