import { ReleaseStatusWithColorBar } from '@mid-react-common/common';
import { GridColDef, GridRenderCellParams } from '@weave-mui/data-grid';
import text from '../../global/text.json';
import ReleaseFolderName from './ReleaseFolderName';
import { ACC_BRIDGE_STATUS, AccBridgeDataForIconsWithTooltip, AccBridgeStatus, DCProductUIExtension } from 'mid-types';
import { AccBridgeSourceProjectDataQueryParams, getDcApiServiceInstance } from 'mid-api-services';
import { ProductsUtils } from 'mid-addin-lib';
import { logError } from 'mid-utils';
import { ProductRelease } from '@adsk/offsite-dc-sdk';

const productStoreText = text.productsStore;

interface ReleasesTableFolderData {
  tenancyId: string;
  contentId: string;
  folderUrn: string | undefined;
  accBridgeStatus: AccBridgeStatus | undefined;
  accBridgeData: AccBridgeDataForIconsWithTooltip | undefined;
}

export const getColumns: GridColDef[] = [
  {
    field: 'releaseNumber',
    headerName: text.releases.releasesTableColumnReleaseNumber,
    width: 200,
    minWidth: 200,
  },
  {
    field: 'releasedOn',
    headerName: text.releases.releasesTableColumnReleasedOn,
    width: 300,
    minWidth: 200,
  },
  {
    field: 'folder',
    headerName: text.releases.releasesTableColumnFolder,
    width: 300,
    minWidth: 200,
    renderCell: (params: GridRenderCellParams<ReleasesTableFolderData>): JSX.Element => {
      const { tenancyId, folderUrn, accBridgeData } = params.value;
      return <ReleaseFolderName tenancyId={tenancyId} folderUrn={folderUrn} accBridgeData={accBridgeData} />;
    },
  },
  {
    field: 'status',
    headerName: text.releases.releasesTableColumnStatus,
    width: 300,
    minWidth: 200,
    renderCell: (params) => <ReleaseStatusWithColorBar status={params.value} />,
  },
];

export const getFolderUrnFromFolderPath = (folderPath: string): string => {
  const fullPath = folderPath.split('/');
  const folderUrn = fullPath[fullPath.length - 1];
  return folderUrn;
};

export const getFolderOutgoingBridgeStatus = (folderUrn: string, outgoingBridgeFolderUrns?: string[]): AccBridgeStatus =>
  outgoingBridgeFolderUrns?.includes(folderUrn) ? ACC_BRIDGE_STATUS.OUTGOING : ACC_BRIDGE_STATUS.NONE;

export const getProductReleasesWithRules = async ({
  projectId,
  productId,
  accBridgeStatus,
  incomingAccBridgeData,
  signal,
}: {
  projectId: string;
  productId: string;
  accBridgeStatus: AccBridgeStatus;
  incomingAccBridgeData?: AccBridgeSourceProjectDataQueryParams;
  signal: AbortSignal;
}): Promise<DCProductUIExtension<ProductRelease>[]> => {
  const dcApiService = getDcApiServiceInstance();
  const releases: DCProductUIExtension<ProductRelease>[] = await dcApiService.getProductReleasesList({
    projectId,
    productId,
    incomingAccBridgeData,
    signal,
  });

  // We apply rules to the inputs to derive a calculated label field in the release details.
  const productReleasesWithBridgeStatusAndRules = await Promise.all(
    releases.map(async (productRelease): Promise<DCProductUIExtension<ProductRelease>> => {
      if (productRelease.rulesKey && !productRelease.rules) {
        try {
          const rules = await ProductsUtils.checkAndDownloadProductRulesFromKey({
            tenancyId: projectId,
            rulesKey: productRelease.rulesKey,
            incomingAccBridgeData: { ...incomingAccBridgeData, targetProjectId: projectId },
          });
          return {
            ...productRelease,
            rules,
            accBridgeStatus,
          };
        } catch (err) {
          logError(productStoreText.failedToFetchRules, err);
        }
      }
      return {
        ...productRelease,
        accBridgeStatus,
      };
    }),
  );

  return productReleasesWithBridgeStatusAndRules;
};
