import { DynamicContent, ProductRelease } from '@adsk/offsite-dc-sdk';
import { DATETIME_FORMAT, useAccBridge, useLogAndShowNotification } from '@mid-react-common/common';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { GridRowId, GridRowModel, GridRowSelectionModel, useGridApiRef } from '@weave-mui/data-grid';
import { format } from 'date-fns';
import { debounce } from 'lodash';
import { useContext, useMemo, useState } from 'react';
import AccountProjectContext from '../../context/AccountProjectStore/AccountProject.context';
import { ACC_BRIDGE_STATUS, AccBridgeDataForIconsWithTooltip, accProjectPlatform, DCProductUIExtension } from 'mid-types';
import { getFolderUrnFromFolderPath, getProductReleasesWithRules } from './Releases.utils';
import { QueryObserverResult, RefetchOptions, useQueries, useQuery } from '@tanstack/react-query';
import { ProductsUtils } from 'mid-addin-lib';
import text from '../../global/text.json';
import { getACCBridgeProjectsData, getACCBridgeStatus } from 'mid-utils';
import {
  GET_ALL_PRODUCTS_IN_TARGET_PROJECT,
  GET_BRIDGE_PRODUCTS,
  GET_PRODUCT_RELEASES,
} from 'global/constants/reactQueryKeys';

const releasesText = text.releases;

interface UseReleasesState {
  tableLoading: boolean;
  productsLoading: boolean;
  productReleases: DCProductUIExtension<ProductRelease>[];
  releaseTableData: GridRowModel[];
  productListFiltered: DCProductUIExtension<DynamicContent>[];
  selectedProduct: DCProductUIExtension<DynamicContent> | null;
  gridApiRef: React.MutableRefObject<GridApiPremium>;
  detailsVisibility: boolean;
  selectedReleasesIds: GridRowId[];
  handleFilter: (event: React.FormEvent<HTMLInputElement>) => void;
  handleProductSelection: (product: DCProductUIExtension<DynamicContent>) => void;
  handleDetailsVisibilityClick: () => void;
  onReleaseSelection: (selectedReleases: GridRowSelectionModel) => void;
  handleCloseDetails: () => void;
  refetchProductReleases: (
    options?: RefetchOptions,
  ) => Promise<QueryObserverResult<DCProductUIExtension<ProductRelease>[], Error>>;
}

const useReleases = (): UseReleasesState => {
  const gridApiRef = useGridApiRef();
  const { projectId, currentProject } = useContext(AccountProjectContext);
  const [selectedProduct, setSelectedProduct] = useState<DCProductUIExtension<DynamicContent> | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [detailsVisibility, setDetailsVisibility] = useState<boolean>(false);
  const [selectedReleasesIds, setSelectedReleasesIds] = useState<GridRowId[]>([]);
  const { incomingBridgeFoldersMap, outgoingBridgeFoldersMap, bridgeProjectsList } = useAccBridge({
    projectId,
    isAccProject: currentProject?.platform === accProjectPlatform.acc,
  });

  const handleProductSelection = (product: DCProductUIExtension<DynamicContent>) => {
    if (product.contentId === selectedProduct?.contentId) {
      return;
    }

    setSelectedProduct(product);
  };

  const handleFilter = (event: React.FormEvent<HTMLInputElement>) => {
    setSearchQuery((event.target as HTMLInputElement).value);
  };

  const debouncedHandleFilter = debounce(handleFilter, 500);

  const filterData = (query: string, products: DCProductUIExtension<DynamicContent>[]) => {
    if (!query) {
      return products;
    }
    return products.filter((product) => product.name.toLowerCase().includes(query.toLowerCase()));
  };

  const handleDetailsVisibilityClick = () => {
    setDetailsVisibility((visibility) => !visibility);
  };

  const onReleaseSelection = (selectedReleases: GridRowSelectionModel) => {
    setDetailsVisibility(true);
    setSelectedReleasesIds(selectedReleases.map((release) => release));
  };

  const handleCloseDetails = () => {
    setDetailsVisibility(false);
  };

  const {
    data: products,
    error: productsError,
    isFetching: productsIsLoading,
  } = useQuery({
    queryKey: [GET_ALL_PRODUCTS_IN_TARGET_PROJECT, projectId!],
    queryFn: async ({ queryKey: [, projectId], signal }) =>
      (
        await ProductsUtils.getAllProductsInProject({ projectId, enableMultiValuesBackwardsCompatibility: true }, signal)
      ).map((p: DynamicContent): DCProductUIExtension<DynamicContent> => ({ ...p, isFromAccBridge: false })),
    initialData: [],
    enabled: Boolean(projectId),
  });
  useLogAndShowNotification(productsError, releasesText.failedToLoadProducts);

  const {
    data: bridgeProducts,
    errors: bridgeProductsErrors,
    isFetching: bridgeProductsIsLoading,
  } = useQueries({
    queries: Array.from(incomingBridgeFoldersMap?.keys() || []).map((targetFolderUrn) => ({
      queryKey: [GET_BRIDGE_PRODUCTS, projectId!, targetFolderUrn],
      queryFn: async () =>
        (await ProductsUtils.getAllProductsInBridgeFolder({ projectId: projectId!, targetFolderUrn })).map(
          (p: DynamicContent): DCProductUIExtension<DynamicContent> => ({ ...p, isFromAccBridge: true, targetFolderUrn }),
        ),
      enabled: Boolean(projectId),
    })),
    combine: (results) => ({
      data: results.flatMap((result) => result.data || []),
      isFetching: results.some((result) => result.isPending),
      errors: results.reduce((prevRes: Error[], currRes) => {
        if (currRes.isError) {
          prevRes.push(currRes.error);
        }
        return prevRes;
      }, []),
    }),
  });

  useLogAndShowNotification(bridgeProductsErrors.length > 0, releasesText.failedToLoadBridgeProducts);

  const productList = useMemo(() => [...products, ...bridgeProducts], [products, bridgeProducts]);
  const productListFiltered = useMemo(() => filterData(searchQuery, productList), [searchQuery, productList]);

  const fetchProductReleases = async (
    product: DCProductUIExtension<DynamicContent>,
    projectId: string,
    signal: AbortSignal,
  ): Promise<DCProductUIExtension<ProductRelease>[]> => {
    let releases: DCProductUIExtension<ProductRelease>[] = [];
    const sourceFolderUrnLastPath = getFolderUrnFromFolderPath(product.context.workspace.folderPath);
    const incomingACCBridgeFolder = incomingBridgeFoldersMap?.get(product.targetFolderUrn || '');
    const outgoingACCBridgeFolders = outgoingBridgeFoldersMap?.get(sourceFolderUrnLastPath);
    const accBridgeStatus = getACCBridgeStatus(incomingACCBridgeFolder, outgoingACCBridgeFolders);

    if (product.isFromAccBridge) {
      releases = await getProductReleasesWithRules({
        projectId,
        productId: product.contentId,
        accBridgeStatus,
        incomingAccBridgeData: {
          sourceFolderUrn: product.context.workspace.folderPath,
          sourceProjectId: product.tenancyId,
          targetProjectId: projectId,
        },
        signal,
      });
    } else {
      releases = await getProductReleasesWithRules({
        projectId,
        productId: product.contentId,
        accBridgeStatus,
        signal,
      });
    }

    return releases;
  };

  // Fetch all releases on product selection
  const {
    data: productReleases,
    isFetching: isFetchingProductReleases,
    refetch: refetchProductReleases,
  } = useQuery({
    queryKey: [GET_PRODUCT_RELEASES, projectId, selectedProduct],
    queryFn:
      projectId && selectedProduct ? ({ signal }) => fetchProductReleases(selectedProduct, projectId, signal) : undefined,
    initialData: [],
    enabled: Boolean(projectId && selectedProduct),
  });

  // Generate table rows
  const generateReleaseTableRows = (productReleases: DCProductUIExtension<ProductRelease>[]) => {
    const productReleasesTableData = productReleases
      .map((release) => {
        const isReleaseFromIncomingBridge = release.accBridgeStatus === ACC_BRIDGE_STATUS.INCOMING;

        const tenancyId = isReleaseFromIncomingBridge ? projectId : release.tenancyId;
        const folderUrn = isReleaseFromIncomingBridge
          ? selectedProduct?.targetFolderUrn
          : getFolderUrnFromFolderPath(release.context.workspace.folderPath);

        let accBridgeData: AccBridgeDataForIconsWithTooltip | undefined;

        if (folderUrn) {
          accBridgeData = getACCBridgeProjectsData({
            folderUrn,
            incomingBridgeFoldersMap,
            outgoingBridgeFoldersMap,
            bridgeProjectsList,
          });
        }

        return {
          id: release.release,
          releaseNumber: release.release,
          releasedOn: format(Date.parse(release.createdAt), DATETIME_FORMAT),
          status: release.status,
          folder: {
            tenancyId,
            contentId: release.contentId,
            folderUrn,
            accBridgeStatus: release.accBridgeStatus,
            accBridgeData,
          },
        };
      })
      .sort((a, b) => a.releaseNumber - b.releaseNumber);
    return productReleasesTableData;
  };

  const releaseTableData: GridRowModel[] = generateReleaseTableRows(productReleases || []);

  return {
    gridApiRef,
    tableLoading: isFetchingProductReleases,
    productsLoading: productsIsLoading || bridgeProductsIsLoading,
    productListFiltered,
    productReleases,
    releaseTableData,
    selectedProduct,
    handleFilter: debouncedHandleFilter,
    detailsVisibility,
    selectedReleasesIds,
    handleProductSelection,
    handleDetailsVisibilityClick,
    handleCloseDetails,
    onReleaseSelection,
    refetchProductReleases,
  };
};

export default useReleases;
