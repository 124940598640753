import Bugsnag from '@bugsnag/js';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { IHandleAmplitudeIdentify, ISetAmplitudeGroup, getDomainFromEmail, logError, midLogger } from 'mid-utils';
import { useCallback, useContext, useEffect, useState } from 'react';
import text from '../../common.text.json';
import NotificationContext from '../../context/NotificationStore/Notification.context';
import { NOTIFICATION_STATUSES } from '../../context/NotificationStore/notificationStore.types';
import { LD_ANALYTICS_KIND } from '../../global/featureFlags';
import { useCancellablePromise } from '../../hooks/misc';
import { getDcApiServiceInstance, getForgeApiServiceInstance } from 'mid-api-services';

export const amplitudeGroupTypeCompanyName = 'companyName';
export const amplitudeGroupTypeDataSourceMoniker = 'sourceMoniker';

interface Analytics {
  analyticsId: string | undefined;
  isAnalyticsIdIdentifiedInLD: boolean;
}

export function useAnalytics(
  webComponentMoniker: string,
  handleAmplitudeIdentify?: IHandleAmplitudeIdentify,
  setAmplitudeGroup?: ISetAmplitudeGroup,
): Analytics {
  const { showNotification } = useContext(NotificationContext);

  const [analyticsId, setAnalyticsId] = useState<string | undefined>(undefined);
  const [isAnalyticsIdIdentifiedInLD, setIsAnalyticsIdIdentifiedInLD] = useState(false);

  const ldClient = useLDClient();
  const cancellablePromise = useCancellablePromise();
  const forgeApiService = getForgeApiServiceInstance();

  const setUsersInThirdPartyApps = useCallback(
    async (analyticsId: string, userEmail: string, userOxygenID: string) => {
      // Bugsnag
      Bugsnag.setUser(analyticsId);

      // LaunchDarkly
      if (ldClient && analyticsId) {
        const ldContext = { kind: LD_ANALYTICS_KIND, key: analyticsId };
        // make api call to get server generated hash
        const hash = await cancellablePromise(getDcApiServiceInstance().getHashForLaunchDarkly(ldContext));
        await ldClient.identify(ldContext, hash);
        // update mid logger to have ld client.
        midLogger.updateLDClient(ldClient);
        setIsAnalyticsIdIdentifiedInLD(true);
      }

      if (!handleAmplitudeIdentify || !setAmplitudeGroup) {
        return;
      }

      // Amplitude
      handleAmplitudeIdentify(userOxygenID, {
        userEmail,
        userOxygenID,
      });

      // We use groups to easily filter data in Amplitude
      // We need the group type and the value we assign to it.
      // We are classifying the data by the following groups: company name and data source (IVTW, RVTW, etc).
      setAmplitudeGroup(amplitudeGroupTypeCompanyName, getDomainFromEmail(userEmail));
      setAmplitudeGroup(amplitudeGroupTypeDataSourceMoniker, webComponentMoniker);

      // Gainsight PX / Apptrinsic
      if (window.aptrinsic) {
        window.aptrinsic('identify', {
          id: userOxygenID,
          email: userEmail,
          company: getDomainFromEmail(userEmail),
          analyticsId,
          userOxygenID,
        });
      }
    },
    [cancellablePromise, handleAmplitudeIdentify, ldClient, setAmplitudeGroup, webComponentMoniker],
  );

  useEffect(() => {
    const getAnalyticsIdAndSetUpBugsnag = async () => {
      try {
        const { emailId, userId } = await cancellablePromise(forgeApiService.getUserProfile());
        const fetchedAnalyticsId = await cancellablePromise(forgeApiService.getUserAnalyticsId(userId));
        setAnalyticsId(fetchedAnalyticsId);

        await setUsersInThirdPartyApps(fetchedAnalyticsId, emailId, userId);
      } catch (error) {
        logError(error);
        showNotification({
          message: text.notificationGetUserProfileFailed,
          severity: NOTIFICATION_STATUSES.ERROR,
        });
      }
    };

    if (!analyticsId) {
      getAnalyticsIdAndSetUpBugsnag();
    }
  }, [
    setAnalyticsId,
    showNotification,
    forgeApiService,
    cancellablePromise,
    handleAmplitudeIdentify,
    analyticsId,
    setAmplitudeGroup,
    setUsersInThirdPartyApps,
  ]);

  return { analyticsId, isAnalyticsIdIdentifiedInLD };
}
