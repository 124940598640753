import { BatchFetchProductsResult, DCRules } from 'mid-types';
import { DCInputNumericType, DCInputTextType, DynamicContent, InputType } from '@adsk/offsite-dc-sdk';
import { getContentFromObjectKey } from './cloudStorage';
import { inputsRulesToTemplateRules } from './tools';
import { InputRule } from '../interfaces/productDefinitions';
import { AccBridgeDownloadUrlQueryParams, DcApiService, InversifyTypes, inversifyContainer } from 'mid-api-services';
import { processAllSettledResults } from 'mid-utils';

export interface GetAllProductsInProjectArgs {
  projectId: string;
  enableMultiValuesBackwardsCompatibility: boolean;
}

export interface GetAllProductsInBridgeFolderArgs {
  projectId: string;
  targetFolderUrn: string;
}

interface CheckAndDownloadProductRulesFromKeyArgs {
  tenancyId: string;
  rulesKey: string | undefined;
  incomingAccBridgeData?: AccBridgeDownloadUrlQueryParams;
}

const migrateProductMultiValueInputs = (product: DynamicContent) => {
  const compatibleProduct: DynamicContent = {
    ...product,
    inputs: product.inputs.map((input) => {
      if (input.type === InputType.MULTI_VALUE_TEXT) {
        const textInput: DCInputTextType = {
          type: InputType.TEXT,
          name: input.name,
          label: input.label,
          value: input.value!,
          values: input.values,
          unit: input.unit,
          visible: input.visible,
          allowCustomValue: true,
          readOnly: input.readOnly,
        };
        return textInput;
      }

      if (input.type === InputType.MULTI_VALUE_NUMERIC) {
        const numericInput: DCInputNumericType = {
          type: InputType.NUMERIC,
          name: input.name,
          label: input.label,
          value: input.value!,
          values: input.values,
          min: input.min,
          max: input.max,
          unit: input.unit,
          visible: input.visible,
          allowCustomValue: true,
          readOnly: input.readOnly,
          onChange: input.onChange,
        };
        return numericInput;
      }

      return input;
    }),
  };
  return compatibleProduct;
};

const ProductsUtils = {
  getAllProductsInProject: async (
    { projectId, enableMultiValuesBackwardsCompatibility }: GetAllProductsInProjectArgs,
    signal?: AbortSignal,
  ): Promise<DynamicContent[]> => {
    const dcApiService = inversifyContainer.get<DcApiService>(InversifyTypes.DcApiService);
    const productsList: DynamicContent[] = await dcApiService.getProductsList({ projectId, signal });

    if (enableMultiValuesBackwardsCompatibility) {
      return productsList.map(migrateProductMultiValueInputs);
    }
    return productsList;
  },

  getAllProductsInBridgeFolder: async (
    { projectId, targetFolderUrn }: GetAllProductsInBridgeFolderArgs,
    signal?: AbortSignal,
  ): Promise<DynamicContent[]> => {
    const dcApiService = inversifyContainer.get<DcApiService>(InversifyTypes.DcApiService);
    const productsList: DynamicContent[] = await dcApiService.getProductsList({ projectId, targetFolderUrn, signal });

    return productsList;
  },

  getProduct: async (
    projectId: string,
    productId: string,
    enableMultiValuesBackwardsCompatibility: boolean,
  ): Promise<DynamicContent> => {
    const dcApiService = inversifyContainer.get<DcApiService>(InversifyTypes.DcApiService);
    const product: DynamicContent = await dcApiService.getProduct(projectId, productId);

    if (enableMultiValuesBackwardsCompatibility) {
      const compatibleProduct: DynamicContent = migrateProductMultiValueInputs(product);
      return compatibleProduct;
    }
    return product;
  },

  getAllProductsForProjects: async (
    projectIds: string[],
    enableMultiValuesBackwardsCompatibility: boolean,
  ): Promise<BatchFetchProductsResult | null> => {
    if (projectIds.length === 0) {
      return null;
    }

    const resultsPromise = projectIds.map(
      async (projectId): Promise<DynamicContent[]> =>
        await ProductsUtils.getAllProductsInProject({ projectId, enableMultiValuesBackwardsCompatibility }),
    );
    const allSettledResult = await Promise.allSettled<DynamicContent[]>(resultsPromise);
    const processedAllSettledResult = processAllSettledResults(allSettledResult);
    return processedAllSettledResult;
  },

  checkAndDownloadProductRulesFromKey: async ({
    tenancyId,
    rulesKey,
    incomingAccBridgeData,
  }: CheckAndDownloadProductRulesFromKeyArgs): Promise<DCRules | undefined> => {
    if (rulesKey) {
      const inputRules = await getContentFromObjectKey<InputRule[]>(tenancyId, rulesKey, incomingAccBridgeData);
      if (inputRules && inputRules.length > 0) {
        return inputsRulesToTemplateRules(inputRules);
      }
    }
  },
};

export default ProductsUtils;
