import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Box from '@mui/material/Box';
import { removePath, removeExtension } from 'components/LegacyOutputsPage/LegacySettingsPanel/outputSettings.utils';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import text from 'global/text.json';
import CancelIcon from '@mui/icons-material/Cancel';
import { Spacing } from '@mid-react-common/common';
import { rfoModalTestIds } from 'tests/helpers/dataTestIds';
import { useTheme } from '@mui/material/styles';
const fileTypesStepText = text.rfoModal.fileTypesStep;

interface Drawing2DDropdownProps {
  label: string;
  drawings: string[];
  selectedDrawings: string[];
  handleOnChange: (event: SelectChangeEvent<string[]>) => void;
  handleDelete: (event: any, value: string) => void;
}

const Drawing2DDropdown: React.FC<Drawing2DDropdownProps> = ({
  label,
  drawings,
  selectedDrawings,
  handleOnChange,
  handleDelete,
}) => {
  const theme = useTheme();
  return (
    <FormControl fullWidth size="small">
      <InputLabel>{label}</InputLabel>
      <Select
        data-testid={rfoModalTestIds.fileTypesStep.drawing2dDropdowns}
        label={label}
        variant="outlined"
        size="small"
        fullWidth
        multiple
        value={selectedDrawings}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: `${theme.var.gapBase / 2}px` }}>
            {selected.map((value, index) => {
              const displayValue = removePath(removeExtension(value));
              return (
                <Chip
                  data-testid={`${rfoModalTestIds.fileTypesStep.drawingOutputChip}-${displayValue}`}
                  key={`${displayValue}-${index}`}
                  label={displayValue}
                  deleteIcon={
                    <CancelIcon
                      onMouseDown={(e) => e.stopPropagation()}
                      data-testid={`${rfoModalTestIds.fileTypesStep.chipRemoveButton}-${displayValue}`}
                    />
                  }
                  onDelete={(e) => handleDelete(e, value)}
                />
              );
            })}
          </Box>
        )}
        onChange={handleOnChange}
      >
        {drawings.length > 1 && (
          <MenuItem value={fileTypesStepText.all} data-testid={rfoModalTestIds.fileTypesStep.allDrawingsMenuItem}>
            <Checkbox
              checked={drawings.length === selectedDrawings.length}
              indeterminate={selectedDrawings.length > 0 && selectedDrawings.length < drawings.length}
            />
            <ListItemText primary={fileTypesStepText.allDrawings} />
          </MenuItem>
        )}
        {drawings.length > 1 && <Divider />}
        {drawings.map((drawing, index) => {
          const displayValue = removePath(removeExtension(drawing));
          return (
            <MenuItem
              key={`${displayValue}-${index}`}
              value={drawing}
              data-testid={`${rfoModalTestIds.fileTypesStep.drawingOutputMenuItem}-${displayValue}`}
            >
              <Checkbox checked={selectedDrawings.includes(drawing)} />
              <ListItemText primary={displayValue} />
            </MenuItem>
          );
        })}
      </Select>
      <Spacing />
    </FormControl>
  );
};

export default Drawing2DDropdown;
