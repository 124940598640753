import { Tree } from 'types/common';
import { UploadLocationProject } from 'types/uploadLocation';
import { StateCreator } from 'zustand';

export interface UploadLocationStepSlice {
  uploadFolderUrn: string | undefined;
  setUploadFolderUrn: (uploadFolderUrn: string | undefined) => void;

  uploadLocationTree: Tree | undefined;
  setUploadLocationTree: (uploadLocationTree: Tree | undefined) => void;

  uploadLocationProject: UploadLocationProject | null;
  setUploadLocationProject: (uploadLocationProject: UploadLocationProject | null) => void;

  isCreatingSubfolder: boolean;
  setIsCreatingSubfolder: (isCreatingSubfolder: boolean) => void;

  expandedUploadLocationTreeNodeIdsSelection: string[];
  setExpandedUploadLocationTreeNodeIdsSelection: (expandedUploadLocationTreeNodeIdsSelection: string[]) => void;

  initializeFolderTreeState: () => void;

  editPermissions: Record<string, boolean>;
  addEditPermissions: (newPermissions: Record<string, boolean>) => void;
}

export const createUploadLocationStepSlice: StateCreator<UploadLocationStepSlice> = (set) => ({
  uploadFolderUrn: '',
  setUploadFolderUrn: (uploadFolderUrn) => set({ uploadFolderUrn }),

  uploadLocationTree: undefined,
  setUploadLocationTree: (uploadLocationTree: Tree | undefined) => set({ uploadLocationTree }),

  uploadLocationProject: null,
  setUploadLocationProject: (uploadLocationProject: UploadLocationProject | null) => set({ uploadLocationProject }),

  isCreatingSubfolder: false,
  setIsCreatingSubfolder: (isCreatingSubfolder: boolean) => set({ isCreatingSubfolder }),

  expandedUploadLocationTreeNodeIdsSelection: [],
  setExpandedUploadLocationTreeNodeIdsSelection: (expandedUploadLocationTreeNodeIdsSelection: string[]) =>
    set({ expandedUploadLocationTreeNodeIdsSelection }),

  initializeFolderTreeState: () =>
    set({
      uploadFolderUrn: undefined,
      uploadLocationTree: undefined,
      expandedUploadLocationTreeNodeIdsSelection: [],
      editPermissions: {},
    }),

  editPermissions: {},
  addEditPermissions: (newPermissions: Record<string, boolean>) =>
    set((prevState) => ({ editPermissions: { ...prevState.editPermissions, ...newPermissions } })),
});
