import { globalConstants } from './midTheme.constants.global';
import { midwConstants } from './midTheme.constants.midw';
import { midefConstants } from './midTheme.constants.midef';
import { rvtucConstants } from './midTheme.constants.rvtuc';
export { default as themeColors } from './midTheme.colors';
export { default as themeTypography } from './midTheme.typography';
export const themeScreenSizes = {
  tabletPortrait: '768px',
  tabletLandscape: '1024px',
  computer: '1200px',
};

// Global Layout
const headerHeight = 36 + (globalConstants.paddingBase / 2) * 2; // element + padding * 2 for top and bottom
const footerHeight = 60;
const borderHeight = 1;
const tabsHeight = 48 + borderHeight; // element + border
const borderRadius = 10;
const normalCircularProgressSize = 40;
const dropdownHeight = 40;
const h1Height = 26;
const gutterBottomHeight = 7;
const helperTextHeight = 25;

// Product definition selection layout
const productDefinitionTableHeaderHeight = 32 + globalConstants.marginBase * 2; // element + margin * 2 for top and bottom

// Inputs tab layout
const inputsSelectionHeaderHeight = 41 + 2 * globalConstants.marginBase * 2; // element + (multiplier * margin) * 2 for top and bottom
const inputsSidebarMinWidth = 200;
const adoptedInputsLabelHeight = 26 + 2.5 * globalConstants.marginBase * 2; // element + (multiplier * margin) * 2 for top and bottom

// Rules tab layout
const formPreviewControlsHeight = 60;
const iLogicAlertHeight = 68;
const iLogicAlertBorderLeft = 5;
const inventorCustomizationFormHeaderHeight = formPreviewControlsHeight + globalConstants.paddingBase * 2 + borderHeight; // element + padding * 2 for top and bottom + border

// Output tab
const drawingsOutputTypeCellWidth = 60;
const drawingsSwitchCellWidth = 150;
const outputTabInputWidth = 320;

// Publish Page
const releaseDetailsHeight =
  h1Height + gutterBottomHeight + dropdownHeight + helperTextHeight + borderHeight + globalConstants.marginBase * 2 * 2;
const publishLocationHeight = h1Height + gutterBottomHeight + dropdownHeight;
const publishHeaderHeight = 51 + globalConstants.paddingBase * 2 * 2; // element + padding * 2 for top and bottom

// Icons
const navIconSize = 80;
const blocklyIconSize = 20;
const formNotificationIconSize = 30;

// Nav
const navBorderWidth = 3;

// Buttons
const largeButtonHeight = 36;

// Inputs
const narrowInputControl = 150;
const wideInputControl = 300;

// Spinners
const circularProgressSize = 120;
const circularProgressSizeSmall = 60;
const circularProgressThickness = 2;
const buttonSpinnerSize = 25;
const smallButtonSpinnerSize = 20;

// Tree
const midTreeMinWidth = 400;

// Mui Datagrid
const muiTableRowHeight = 52;

// ProductFolderBrowser
const productFolderBrowserGridHeaderHeight = 35;
const productFolderBrowserMinHeight = 400;
const releasesHeight = 72;

// Notification Snackbar
const notificationSnackBarHeight = 50;
const notificationSnackBarsGap = 10;
const notificationSnackBarInitialTopOffset = 24;

// Summary Table
const summaryTableCellPadding = 10;

// Publishing Invalid
const publishingPrerequisitesTitle = 33;

// Misc
const drawingsSize = 150;
const dialogHeight = 500;
const productFormHorizontalSpacing = globalConstants.paddingBase * 2.5;
const dataGridEmptyLoadingHeight = 300;
const releaseTextLetterSpacing = 1;

// Product Customization Footer (RVTW and MIDEF)
const representationEditButtonMaxWidth = 220;

// ACC Bridge
const accBridgeIconSize = 16;

export const themeVar = {
  ...globalConstants,
  publishingPrerequisitesTitle,
  notificationSnackBarHeight,
  notificationSnackBarsGap,
  notificationSnackBarInitialTopOffset,
  summaryTableCellPadding,
  circularProgressSize,
  inputsSelectionHeaderHeight,
  productDefinitionTableHeaderHeight,
  circularProgressSizeSmall,
  circularProgressThickness,
  buttonSpinnerSize,
  publishLocationHeight,
  publishHeaderHeight,
  blocklyIconSize,
  releaseDetailsHeight,
  formNotificationIconSize,
  smallButtonSpinnerSize,
  formPreviewControlsHeight,
  inventorCustomizationFormHeaderHeight,
  iLogicAlertHeight,
  iLogicAlertBorderLeft,
  headerHeight,
  tabsHeight,
  footerHeight,
  adoptedInputsLabelHeight,
  topBars: headerHeight + tabsHeight,
  largeButtonHeight,
  muiTableRowHeight,
  drawingsSize,
  releasesHeight,
  dialogHeight,
  borderHeight,
  wideInputControl,
  narrowInputControl,
  midTreeMinWidth,
  dropdownHeight,
  productFolderBrowserGridHeaderHeight,
  productFolderBrowserMinHeight,
  navIconSize,
  navBorderWidth,
  inputsSidebarMinWidth,
  drawingsOutputTypeCellWidth,
  drawingsSwitchCellWidth,
  productFormHorizontalSpacing,
  dataGridEmptyLoadingHeight,
  borderRadius,
  normalCircularProgressSize,
  releaseTextLetterSpacing,
  outputTabInputWidth,
  representationEditButtonMaxWidth,
  accBridgeIconSize,
  ...midwConstants,
  ...midefConstants,
  ...rvtucConstants,
};
