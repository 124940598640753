import { AuthClientOptions, CacheLocation } from '@adsk/auth-pkce-sdk';
import { ENVIRONMENT } from 'mid-types';
import { getReactAppEnvironment } from 'mid-utils';

// Configuration for the ADSK SDK authentication using PKCE (Proof Key for Code Exchange)
const authPkceSdkEnv =
  getReactAppEnvironment() === ENVIRONMENT.MOCK ||
  getReactAppEnvironment() === ENVIRONMENT.DEV ||
  getReactAppEnvironment() === ENVIRONMENT.STG
    ? ENVIRONMENT.STG
    : getReactAppEnvironment();

const cacheMethod: CacheLocation = 'sessionstorage';
export const authPkceSdkConfig: AuthClientOptions = {
  client_id: import.meta.env.VITE_AUTH_CONFIG_CLIENT_ID || 'CONFIGURE_IN_ENV',
  redirect_uri: window.location.origin + '/',
  useRefreshTokens: true,
  cacheLocation: cacheMethod,
  env: authPkceSdkEnv,
  scope:
    'user-profile:read user:read data:read data:write data:create data:search bucket:create bucket:read bucket:update bucket:delete account:read account:write',
};
