import { StateSetter } from '@mid-react-common/common';
import { GridRowId } from '@weave-mui/data-grid';
import { BIM360Document } from 'mid-types';
import { useState } from 'react';
import { FolderContentRow } from '../../components/OutputsPage/ModelsFolderContent/ModelsFolderContent.types';
import { Tree } from '../../types/common';

export interface ModelSelectionStore {
  projectFoldersTree: Tree | undefined;
  setProjectFoldersTree: (foldersTree: Tree | undefined) => void;
  expandedProjectFoldersTreeNodeIds: string[];
  setExpandedProjectFoldersTreeNodeIds: (treeNodeIds: string[]) => void;
  selectedModelFolder: FolderContentRow | null;
  setSelectedModelFolder: StateSetter<FolderContentRow | null>;
  productFoldersContent: FolderContentRow[];
  setProductFoldersContent: StateSetter<FolderContentRow[]>;
  selectedModelId: GridRowId | undefined;
  setSelectedModelId: StateSetter<GridRowId | undefined>;
  currentlyOpenModel: FolderContentRow | null;
  setCurrentlyOpenModel: StateSetter<FolderContentRow | null>;
  resetModelSelectionStoreState: () => void;
  selectedFolderUrn: string;
  setSelectedFolderUrn: StateSetter<string>;
  lmvModelFile: BIM360Document[] | null;
  setLmvModelFile: StateSetter<BIM360Document[] | null>;
}
export const useModelSelectionStore = (): ModelSelectionStore => {
  const [selectedModelFolder, setSelectedModelFolder] = useState<FolderContentRow | null>(null);
  const [selectedFolderUrn, setSelectedFolderUrn] = useState('');
  const [productFoldersContent, setProductFoldersContent] = useState<FolderContentRow[]>([]);
  const [expandedProjectFoldersTreeNodeIds, setExpandedProjectFoldersTreeNodeIds] = useState<string[]>([]);
  const [projectFoldersTree, setProjectFoldersTree] = useState<Tree | undefined>(undefined);
  const [selectedModelId, setSelectedModelId] = useState<GridRowId | undefined>(undefined);
  const [currentlyOpenModel, setCurrentlyOpenModel] = useState<FolderContentRow | null>(null);
  const [lmvModelFile, setLmvModelFile] = useState<BIM360Document[] | null>(null);

  const resetModelSelectionStoreState = () => {
    setProjectFoldersTree(undefined);
    setSelectedModelFolder(null);
    setProductFoldersContent([]);
    setExpandedProjectFoldersTreeNodeIds([]);
    setSelectedModelId(undefined);
    setCurrentlyOpenModel(null);
    setSelectedFolderUrn('');
    setLmvModelFile(null);
  };

  return {
    selectedFolderUrn,
    setSelectedFolderUrn,
    projectFoldersTree,
    setProjectFoldersTree,
    expandedProjectFoldersTreeNodeIds,
    setExpandedProjectFoldersTreeNodeIds,
    productFoldersContent,
    setProductFoldersContent,
    selectedModelFolder,
    setSelectedModelFolder,
    selectedModelId,
    setSelectedModelId,
    currentlyOpenModel,
    setCurrentlyOpenModel,
    lmvModelFile,
    setLmvModelFile,
    resetModelSelectionStoreState,
  };
};
