import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useCallback, useState } from 'react';
import text from '../../../global/text.json';
import { UploadLocationProject } from '../../../types/uploadLocation';
import { ProjectIcon } from '../ProjectIcon/ProjectIcon';
import { ProjectList, ProjectListTitle } from './OutputProjectSelector.styles';
import { accountProjectSelectorTestIds } from 'tests/helpers/dataTestIds';

interface ProjectListDropdownProps {
  selectedProjectId: string | undefined;
  projects: UploadLocationProject[];
  handleProjectSelection: (project: UploadLocationProject) => void;
  handleSearchProjects: (value: string) => void;
}

const outputProjectSelectorText = text.outputProjectSelector;

const ProjectListDropdown: React.FC<ProjectListDropdownProps> = ({
  selectedProjectId,
  projects,
  handleProjectSelection,
  handleSearchProjects,
}) => {
  const [inputSearchText, setInputSearchText] = useState('');

  const handleProjectItemClick = useCallback(
    (project: UploadLocationProject) => () => handleProjectSelection(project),
    [handleProjectSelection],
  );

  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = event.target;
      setInputSearchText(value);
      handleSearchProjects(value);
    },
    [handleSearchProjects],
  );

  const onSearchCancel = useCallback(() => {
    setInputSearchText('');
    handleSearchProjects('');
  }, [handleSearchProjects]);

  return (
    <>
      <TextField
        data-testid={accountProjectSelectorTestIds.searchInput}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: inputSearchText ? (
            <IconButton
              disableRipple
              onClick={onSearchCancel}
              data-testid={accountProjectSelectorTestIds.cancelSearchButton}
            >
              <CancelIcon fontSize="small" />
            </IconButton>
          ) : null,
        }}
        fullWidth
        hiddenLabel
        placeholder={outputProjectSelectorText.search}
        onChange={onInputChange}
        value={inputSearchText}
        size="small"
      />
      <ProjectListTitle variant="h6">{outputProjectSelectorText.projects}</ProjectListTitle>
      <Divider />
      <ProjectList>
        {projects.map((project) => (
          <ListItem
            key={project.id}
            data-id={project.id}
            secondaryAction={<ProjectIcon vendor={project.vendor} />}
            disablePadding
          >
            <ListItemButton onClick={handleProjectItemClick(project)} selected={project.id === selectedProjectId}>
              <ListItemText
                primary={
                  <Typography variant="body2">
                    <strong>{project.name}</strong>
                  </Typography>
                }
                secondary={<Typography variant="caption">{project.accountName}</Typography>}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </ProjectList>
    </>
  );
};

export default ProjectListDropdown;
