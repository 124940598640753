import { Vendors } from '@adsk/offsite-dc-sdk';
import { uploadLocationProjectSelectorTestIds } from 'tests/helpers/dataTestIds';
import AccIcon from '../../../images/projectIcons/acc.svg?react';
import Bim360Icon from '../../../images/projectIcons/bim360.svg?react';
import FusionIcon from '../../../images/projectIcons/fusion.svg?react';

interface ProjectIconProps {
  vendor: Vendors;
}

export const ProjectIcon: React.FC<ProjectIconProps> = ({ vendor }) => {
  const projectIconMap: Record<Vendors, JSX.Element> = {
    [Vendors.ACC]: <AccIcon data-testid={uploadLocationProjectSelectorTestIds.accProjectIconType} />,
    [Vendors.BIM360]: <Bim360Icon data-testid={uploadLocationProjectSelectorTestIds.bim360ProjectIconType} />,
    [Vendors.FUSIONTEAM]: <FusionIcon data-testid={uploadLocationProjectSelectorTestIds.fusionProjectIconType} />,
  };

  return projectIconMap[vendor];
};
