import { Tree } from 'types/common';
import { useCallback } from 'react';
import { UploadLocationProject } from 'types/uploadLocation';
import useRFOModalStore from 'context/RFOModalStore/useRFOModalStore';
import { useShallow } from 'zustand/react/shallow';

interface UseUploadLocationStepState {
  uploadLocationProject: UploadLocationProject | null;
  uploadFolderUrn: string | undefined;
  isCreatingSubfolder: boolean;
  uploadLocationTree: Tree | undefined;
  expandedUploadLocationTreeNodeIdsSelection: string[];
  editPermissions: Record<string, boolean>;
  setUploadLocationTree: (uploadLocationTree: Tree | undefined) => void;
  handleProjectSelection: (project: UploadLocationProject) => void;
  handleFolderSelection: (folderUrn: string) => void;
  dismissSubfolderCreationDisplay: () => void;
  setExpandedUploadLocationTreeNodeIdsSelection: (expandedUploadLocationTreeNodeIdsSelection: string[]) => void;
  addEditPermissions: (newPermissions: Record<string, boolean>) => void;
}

export const useUploadLocationStep = (): UseUploadLocationStepState => {
  const {
    isCreatingSubfolder,
    setIsCreatingSubfolder,
    uploadFolderUrn,
    setUploadFolderUrn,
    uploadLocationProject,
    setUploadLocationProject,
    uploadLocationTree,
    setUploadLocationTree,
    initializeFolderTreeState,
    expandedUploadLocationTreeNodeIdsSelection,
    setExpandedUploadLocationTreeNodeIdsSelection,
    editPermissions,
    addEditPermissions,
  } = useRFOModalStore(
    useShallow((state) => ({
      uploadFolderUrn: state.uploadFolderUrn,
      setUploadFolderUrn: state.setUploadFolderUrn,
      isCreatingSubfolder: state.isCreatingSubfolder,
      setIsCreatingSubfolder: state.setIsCreatingSubfolder,
      uploadLocationProject: state.uploadLocationProject,
      setUploadLocationProject: state.setUploadLocationProject,
      uploadLocationTree: state.uploadLocationTree,
      setUploadLocationTree: state.setUploadLocationTree,
      initializeFolderTreeState: state.initializeFolderTreeState,
      expandedUploadLocationTreeNodeIdsSelection: state.expandedUploadLocationTreeNodeIdsSelection,
      setExpandedUploadLocationTreeNodeIdsSelection: state.setExpandedUploadLocationTreeNodeIdsSelection,
      editPermissions: state.editPermissions,
      addEditPermissions: state.addEditPermissions,
    })),
  );

  const handleProjectSelection = useCallback(
    (project: UploadLocationProject) => {
      if (project.id === uploadLocationProject?.id) {
        return;
      }
      initializeFolderTreeState();
      setUploadLocationProject(project);
    },
    [initializeFolderTreeState, setUploadLocationProject, uploadLocationProject?.id],
  );

  const handleFolderSelection = useCallback(
    (folderUrn: string) => {
      setIsCreatingSubfolder(false);

      if (!uploadLocationProject || folderUrn === uploadFolderUrn) {
        return;
      }

      setUploadFolderUrn(folderUrn);
    },
    [setIsCreatingSubfolder, setUploadFolderUrn, uploadFolderUrn, uploadLocationProject],
  );

  const dismissSubfolderCreationDisplay = () => {
    setIsCreatingSubfolder(false);
  };

  return {
    uploadLocationProject,
    uploadFolderUrn,
    isCreatingSubfolder,
    uploadLocationTree,
    expandedUploadLocationTreeNodeIdsSelection,
    editPermissions,
    setUploadLocationTree,
    handleProjectSelection,
    handleFolderSelection,
    dismissSubfolderCreationDisplay,
    setExpandedUploadLocationTreeNodeIdsSelection,
    addEditPermissions,
  };
};
