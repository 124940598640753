import { isUndefined } from 'lodash';
import { useCallback, useContext, useEffect } from 'react';
import { Instance } from 'types/product';
import ProductContext from '../../../../context/ProductStore/Product.context';
import { ViewerSelectionEvent } from '../../../../services/viewer/viewer.types';
import { default as viewerService } from '../../../../services/viewer/viewerService';

interface UseInstancesSelectionProps {
  localSelectedInstances: Instance[] | undefined;
  setLocalSelectedInstances: React.Dispatch<React.SetStateAction<Instance[] | undefined>>;
}

interface UseInstancesSelectionState {
  handleInstancesSelection: (selectedInstanceIds: string[]) => void;
}

const useInstancesSelection = ({
  setLocalSelectedInstances,
  localSelectedInstances,
}: UseInstancesSelectionProps): UseInstancesSelectionState => {
  const { instances, selectedProductRelease, productReleases, dataGridInstances } = useContext(ProductContext);

  const handleInstancesSelection = (selectedInstanceIds: string[]) => {
    const selectedGridInstances = dataGridInstances.filter((dataGridInstance) =>
      selectedInstanceIds.includes(dataGridInstance.id),
    );

    const idsOfSelectedGridInstances: number[] = selectedGridInstances.map((instance) => parseInt(instance.id));
    // There is a callback handleViewerSelectionChange
    // that is triggered by the viewer and that function calls
    // handleInstanceDetailsPanelVisibility and set
    // the selected instances data
    viewerService.select(idsOfSelectedGridInstances);
  };

  // TODO: Handle case where user "shift-selects" an instance that belongs to a different product release
  // What will happen now is that the dataGridInstances will deselct the old instances, change to the correct grid,
  // and the new instance belonging to the new product release will be selected.
  // However, the old instances will still be selected (highlighted) in the viewer.
  const handleViewerSelectionChange = useCallback(
    (event: ViewerSelectionEvent) => {
      const selectedDbIdsInViewer: number[] = event.dbIdArray;

      const lastSelectedInstanceId = selectedDbIdsInViewer.find(
        (dbId) => !localSelectedInstances?.some((instance) => instance.id === dbId.toString()),
      );
      const lastSelectedInstance = instances?.find((instance) => instance.id === lastSelectedInstanceId?.toString());

      const newSelectedGridInstances: Instance[] =
        dataGridInstances?.filter((dataGridInstance) => selectedDbIdsInViewer?.includes(parseInt(dataGridInstance.id))) ||
        [];

      const hasProductChanged =
        !isUndefined(selectedProductRelease) &&
        !isUndefined(lastSelectedInstance) &&
        !(
          lastSelectedInstance.contentId === selectedProductRelease.contentId &&
          lastSelectedInstance.release === selectedProductRelease.release
        );

      if (hasProductChanged) {
        const selectedProductRelease = productReleases.find(
          (productRelease) =>
            productRelease.contentId === lastSelectedInstance?.contentId &&
            productRelease.release === lastSelectedInstance?.release,
        );
        if (!isUndefined(selectedProductRelease)) {
          setLocalSelectedInstances([lastSelectedInstance]);
        }
      } else {
        setLocalSelectedInstances(newSelectedGridInstances);
      }
    },
    [
      dataGridInstances,
      selectedProductRelease,
      instances,
      localSelectedInstances,
      productReleases,
      setLocalSelectedInstances,
    ],
  );

  // Add Viewer selection event listener
  useEffect(() => {
    if (instances && instances.length > 0) {
      viewerService.viewer.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, handleViewerSelectionChange);
      return () => viewerService.viewer.removeEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, () => undefined);
    }
  }, [handleViewerSelectionChange, instances]);

  return {
    handleInstancesSelection,
  };
};

export default useInstancesSelection;
